import { createMachine, assign } from "xstate";
import { produce } from "immer";

const NotificationMachine = createMachine({
  initial: "idle",
  context: {
    alert: null,
  },
  states: {
    idle: {
      on: {
        EV_SHOW: {
          actions: [
            assign({
              alert: (context, event) => {
                return event.alert;
              },
            }),
          ],
        },
        EV_CLEAR: {
          actions: [
            assign({
              alert: () => undefined,
            }),
          ],
        },
      },
    },
  },
});

export default NotificationMachine;
