import { useContext } from "react";
import { Button, CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "@xstate/react";
import { GlobalContext } from "../../global-context";
import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

export default function NoToken() {
  const globalC = useContext(GlobalContext);
  const auth = globalC.auth;
  const isLogged = useSelector(auth, (state) => state.matches("logged"));
  const isNotLogged = useSelector(auth, (state) => state.matches("not_logged"));

  const history = useNavigate();

  if (isLogged) {
    return <Navigate to="/dashboard" />;
  }

  if (isNotLogged) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Card className="login__panel-token">
        <CardContent>
          <Typography variant="h4">Wygeneruj token</Typography>
          <Typography>Aby uzyskać dostęp do yourscreen.pl zeskanuj poniższy kod QR w aplikacji Google Authenticator. W następnym kroku wpisz unikatowy kod wygenerowany w aplikacji. Kod QR wyświetla się tylko raz. W przypadku utraty prosimy o kontakt mailowy helpdesk@screennetwork.pl</Typography>
        </CardContent>
        <img className="token" src={auth.state.context.loginResult.data.data_url} alt="token qr" />
        <CardActions className="actions">
          <Button
            type="submit"
            onClick={() => {
              auth.send("EV_NEXT");
              history({ pathname: "/login/token" }, { replace: true });
            }}
            variant="contained"
            color="primary"
          >
            DALEJ
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
