import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Toolbar, IconButton, MenuItem, Menu, Divider } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { useContext } from "react";
import { GlobalContext } from "./../../global-context";
import { useActor } from "@xstate/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import MessageIcon from "@mui/icons-material/Message";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TocIcon from "@mui/icons-material/Toc";
import Campaign from "@mui/icons-material/Campaign";
import Business from "@mui/icons-material/Business";
import { AccountCircle } from "@mui/icons-material";
import MoreIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import { Route, Routes, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Notifications from "./../../common/notifications";

import IMG_LOGO from "./../../resources/images/logo_small.png";
import StockList from "./Stock/StockList";
import StockMessage from "./Stock/StockMessage";
import CampaignList from "./Campaigns/CampaignList";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard() {
  const globalC = useContext(GlobalContext);
  const auth = globalC.auth;
  const [authState] = useActor(auth);
  const [messagesOpen, setMessagesOpen] = React.useState(false);
  const [campaignsOpen, setCampaignsOpen] = React.useState(false);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const MenuInfo = () => {
    const meta = authState.context.user?.Metadata.find((x) => x.Key === "company");
    return (
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={authState.context.user?.personal_data} secondary={meta?.Value ?? "brak"} />
      </ListItem>
    );
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
            <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <p>Notifications</p>
          </MenuItem> */}
      <MenuItem onClick={handleMenuClose} disableRipple>
        <PersonIcon />
        Mój profil
      </MenuItem>

      <Divider sx={{ my: 0.5 }} />
      <MenuItem
        onClick={() => {
          handleMenuClose();
          auth.send("EV_LOGOUT");
        }}
        disableRipple
      >
        <LogoutIcon />
        Wyloguj
      </MenuItem>
    </Menu>
  );

  const handleMessagesClick = () => {
    setMessagesOpen(!messagesOpen);
  };

  const handleCampaignsClick = () => {
    setCampaignsOpen(!campaignsOpen);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 48,
            height: 48,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disableRipple>
        <List>
          <MenuInfo />
        </List>
      </MenuItem>
      <Divider sx={{ my: 0.5 }} />
      <MenuItem onClick={handleMenuClose} disableRipple sx={{ p: "0.6rem" }}>
        <PersonIcon sx={{ mr: "1rem" }} />
        Mój profil
      </MenuItem>

      <Divider sx={{ my: 0.5 }} />
      <MenuItem
        onClick={() => {
          handleMenuClose();
          auth.send("EV_LOGOUT");
        }}
        disableRipple
        sx={{ p: "0.6rem" }}
      >
        <LogoutIcon sx={{ mr: "1rem" }} />
        Wyloguj
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            <IconButton size="large" edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={IMG_LOGO} alt="logo" />
          <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <MenuInfo />
        </List>
        <Divider />
        <List>
          {["grzegorz.ben@screennetwork.pl","damian.rezner@screennetwork.pl","michal.palczynski@screennetwork.pl", "adam.wojno@screennetwork.pl"].includes(authState.context.user?.email) && (
            <ListItem key="campaigns" disablePadding sx={{ display: "block" }}>
              <ListItemButton onClick={handleCampaignsClick}>
                <ListItemIcon>
                  <Campaign />
                </ListItemIcon>
                <ListItemText primary="Kampanie" />
                {campaignsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={campaignsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/dashboard/campaigns/list" style={{ textDecoration: "none", color: "black" }}>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => {}}>
                      <ListItemIcon>
                        <TocIcon />
                      </ListItemIcon>
                      <ListItemText primary="Lista kampani" sx={{ textDecoration: "none" }} />
                    </ListItemButton>
                  </Link>
                  {/* <Link to="/dashboard/campaigns/clients" style={{ textDecoration: "none", color: "black" }}>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => {}}>
                    <ListItemIcon>
                      <Business />
                    </ListItemIcon>
                    <ListItemText primary="Klienci" sx={{ textDecoration: "none" }} />
                  </ListItemButton>
                </Link> */}
                </List>
              </Collapse>
            </ListItem>
          )}

          <ListItem key="stock" disablePadding sx={{ display: "block" }}>
            <ListItemButton onClick={handleMessagesClick}>
              <ListItemIcon>
                <MessageIcon />
              </ListItemIcon>
              <ListItemText primary="Komunikaty" />
              {messagesOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={messagesOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/dashboard/stock" style={{ textDecoration: "none", color: "black" }}>
                  <ListItemButton sx={{ pl: 4 }} onClick={() => {}}>
                    <ListItemIcon>
                      <ShowChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Giełdowe" sx={{ textDecoration: "none" }} />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Notifications />
        <Routes>
          <Route exact path="/" element={<Navigate to="/dashboard/stock" />} />
          <Route path="/stock" element={<StockList />} />
          <Route path="/stock/add" element={<StockMessage />} />
          <Route path="/stock/edit/:id" element={<StockMessage />} />

          <Route path="/campaigns/clients" element={<StockList />} />
          <Route path="/campaigns/list" element={<CampaignList />} />
        </Routes>
      </Box>
    </Box>
  );
}
