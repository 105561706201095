import React from "react";

import { assign, createMachine } from "xstate";
import { createErrorFromAxiosRejection } from "../../../util";
import axios from "axios";
import AppConfig from "../../../config";
import { useMachine, useActor } from "@xstate/react";
import produce from "immer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import { StockApi } from "./../../../api/stock";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { GlobalContext } from "../../../global-context";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

const ListMachine = createMachine(
  {
    id: "list",
    initial: "idle",

    context: {
      api: axios.create({ baseURL: AppConfig.BaseUrl }),
      page: 0,
      maxPages: 0,
      perPage: 10,
      error: null,
      alerts: [],
      selected: [],
      notifications: [],
    },

    states: {
      idle: {
        initial: "fetch",

        states: {
          fetch: {
            invoke: {
              src: "fetchAlerts",
              onDone: {
                target: "#list.loaded",
                actions: assign({
                  alerts: (_, ev) => ev.data.items,
                  maxPages: (context, ev) => Math.ceil(ev.data.count / context.perPage),
                }),
              },
              onError: [
                {
                  target: "notLogged",
                  cond: (_, event) => {
                    const aErr = createErrorFromAxiosRejection(event.data);
                    return aErr.code === 403;
                  },
                },
                {
                  target: "error",
                  cond: (_, event) => {
                    const aErr = createErrorFromAxiosRejection(event.data);
                    return aErr.code !== 404 && aErr.code !== 403;
                  },
                },
                {
                  target: "notFound",
                  cond: (_, event) => {
                    const aErr = createErrorFromAxiosRejection(event.data);
                    return aErr.code === 404;
                  },
                },
              ],
            },
          },
          notLogged: {
            on: {
              EV_TRY_LOGIN: {
                actions: ["doLogin"],
              },
            },
          },
          error: {
            on: {
              EV_RETRY: "fetch",
            },
          },
          notFound: {
            on: {
              EV_ADD: {
                actions: ["doAdd"],
              },
            },
          },
        },
      },

      loaded: {
        initial: "idle",
        states: {
          idle: {},
          activate: {
            invoke: {
              src: "activate",
              onDone: {
                target: "#list.loaded",
                actions: [
                  (context, _ev) => {
                    context.notifications.send("EV_SHOW", { alert: { message: "Pomyślnie aktywowano powiadomienie giełdowe", variant: "success" } });
                  },
                  assign({
                    alerts: (context, event) =>
                      produce(context.alerts, (draft) => {
                        const item = draft.find((x) => x.id === event.data.id);
                        item.active = 1;
                      }),
                    selected: [],
                  }),
                ],
              },
              onError: {
                target: "#list.loaded",
                actions: [
                  (context, _ev) => {
                    context.notifications.send("EV_SHOW", { alert: { message: "Nie można aktywować powiadomienia giełdowego !", variant: "danger" } });
                  },
                ],
              },
            },
          },

          deactivate: {
            invoke: {
              src: "deactivate",
              onDone: {
                target: "#list.loaded",
                actions: [
                  (context, _ev) => {
                    context.notifications.send("EV_SHOW", { alert: { message: "Dezaktywowano powiadomienie giełdowe !", variant: "warning" } });
                  },
                  assign({
                    alerts: (context, event) =>
                      produce(context.alerts, (draft) => {
                        const item = draft.find((x) => x.id === event.data.id);
                        item.active = 0;
                      }),
                    selected: [],
                  }),
                ],
              },
              onError: {
                target: "#list.loaded",
                actions: [
                  (context, _ev) => {
                    context.notifications.send("EV_SHOW", { alert: { message: "Nie można dezaktuwować powiadomienia giełdowego !", variant: "danger" } });
                  },
                ],
              },
            },
          },
        },
        on: {
          EV_ADD: {
            actions: ["doAdd"],
          },
          EV_NEXT_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (context, _) => context.page + 1,
              }),
            ],
            cond: (context) => context.page + 1 <= context.maxPages - 1,
          },
          EV_PREV_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (context, _) => context.page - 1,
              }),
            ],
            cond: (context) => context.page - 1 >= 0,
          },
          EV_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (_, event) => event.page,
              }),
            ],
            cond: (context, event) => event.page >= 0 && event.page <= context.maxPages - 1,
          },
          EV_LAST_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (context, _) => context.maxPages - 1,
              }),
            ],
          },
          EV_FIRST_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: 0,
              }),
            ],
          },
          EV_CLONE: "clone",
          EV_DELETE: "delete",
          EV_DELETE_BULK: {
            target: "delete_bulk",
            cond: "areAnySelected",
          },
          EV_ACTIVATE: [
            { target: "#list.loaded.activate", cond: "isActive" },
            { target: "#list.loaded.deactivate", cond: "isNotActive" },
          ],
          EV_SELECTED: {
            actions: [
              assign({
                selected: (context, event) =>
                  produce(context.selected, (draft) => {
                    draft.push(event.data);
                  }),
              }),
            ],
          },
          EV_DESELECTED: {
            actions: [
              assign({
                selected: (context, event) =>
                  produce(context.selected, (draft) => {
                    const index = draft.findIndex((a) => a.id === event.data.id);
                    if (index !== -1) draft.splice(index, 1);
                  }),
              }),
            ],
          },
        },
      },

      clone: {
        invoke: {
          src: "cloneAlert",
          onDone: {
            target: "#list.loaded",
            actions: [
              assign({
                notifications: (context, event) =>
                  produce(context.notifications, (draft) => {
                    draft.push({ title: "Alert RCB", message: "Pomyślnie skopiowano alert RCB", variant: "success" });
                  }),
                alerts: (context, event) =>
                  produce(context.alerts, (draft) => {
                    draft.push(event.data);
                  }),
              }),
            ],
          },
          onError: {
            target: "#list.loaded",
            actions: [
              assign({
                notifications: (context, event) =>
                  produce(context.notifications, (draft) => {
                    draft.push({
                      title: "Alert RCB",
                      message: "Nie można skopiować alertu RCB",
                      error: createErrorFromAxiosRejection(event.data),
                      variant: "danger",
                    });
                  }),
              }),
            ],
          },
        },
      },
      delete: {
        invoke: {
          src: "deleteAlert",
          onDone: {
            target: "#list.loaded",
            actions: [
              (context, _ev) => {
                context.notifications.send("EV_SHOW", { alert: { message: "Pomyślnie usunięto komunikat giełdowy !", variant: "success" } });
              },
              assign({
                alerts: (context, event) =>
                  produce(context.alerts, (draft) => {
                    const index = draft.findIndex((a) => a.id === event.data.id);
                    if (index !== -1) {
                      draft.splice(index, 1);
                    }
                  }),
              }),
            ],
          },
          onError: {
            target: "#list.loaded",
            actions: [
              (context, _ev) => {
                context.notifications.send("EV_SHOW", { alert: { message: "Nie można usunąć komunikatu giełdowego !", variant: "danger" } });
              },
            ],
          },
        },
      },

      delete_bulk: {
        invoke: {
          src: "deleteBulkAlerts",
          onDone: {
            target: "idle",
            actions: [
              assign({
                notifications: (context, event) =>
                  produce(context.notifications, (draft) => {
                    draft.push({ title: "Alert RCB", message: "Pomyślnie usunięto wszystkie zaznaczone alerty RCB", variant: "success" });
                  }),
                page: 0,
                selected: [],
              }),
            ],
          },
          onError: {
            target: "#list.loaded",
            actions: [
              assign({
                notifications: (context, event) =>
                  produce(context.notifications, (draft) => {
                    draft.push({
                      title: "Alert RCB",
                      message: "Nie można zaznaczonych alertów RCB",
                      error: createErrorFromAxiosRejection(event.data),
                      variant: "danger",
                    });
                  }),
              }),
            ],
          },
        },
      },
    },
  },
  {
    guards: {
      isActive: (_, event) => event.active === true,
      isNotActive: (_, event) => event.active === false,
      areAnySelected: (context, _) => context.selected.length !== 0,
    },
    services: {
      fetchAlerts: (context, _) => {
        return StockApi.fetch(context.perPage, context.page);
      },
      deleteAlert: (_, event) => StockApi.delete(event.id),
      deleteBulkAlerts: (context, _) => StockApi.deleteBulk(context.selected.map((x) => x.id)),
      activate: (_, event) => StockApi.activate(event.alert.id),
      deactivate: (_, event) => StockApi.deactivate(event.alert.id),
      cloneAlert: (_, event) =>
        StockApi.create({
          ...event.alert,
          id: undefined,
          active: 0,
        }),
    },
  }
);

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));

export default function StockList() {
  const history = useNavigate();

  const globalC = React.useContext(GlobalContext);
  const notifications = globalC.notifications;
  const auth = globalC.auth;

  const [current, send] = useMachine(ListMachine, {
    context: {
      notifications: notifications,
    },
    actions: {
      doLogin: () => {
        auth.send("EV_LOGOUT");
      },
      doAdd: () => {
        history("add");
      },
    },
  });

  return (
    <Container className="list">
      {current.matches("idle.fetch") && <LoadingSkeleton />}

      {current.matches("loaded") && <Show current={current} send={send} />}

      {current.matches("idle.notFound") && <Empty send={send} />}

      {current.matches("idle.notLogged") && <NotLogged send={send} />}
    </Container>
  );
}

function Empty({ send }) {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Alert sx={{ mb: "2rem" }} severity="info">
              Brak alertów giełdowych
            </Alert>
            <Typography variant="body2" color="text.secondary">
              Nie znaleziono żadnych dodanych alertów giełdowych. Kliknij przycisk "Dodaj nowy alert", aby go dodać.
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => send("EV_ADD")} variant="contained" color="secondary">
              Dodaj nowy alert
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

function NotLogged({ send }) {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Alert sx={{ mb: "2rem" }} severity="info">
              Sesja wygasła
            </Alert>
            <Typography variant="body2" color="text.secondary">
              Aby móc korzystać z panelu zaloguj się ponownie
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => send("EV_TRY_LOGIN")} variant="contained" color="secondary">
              Zaloguj się
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

function Show({ current, send }) {
  const entries = current.context.alerts.map((a) => {
    return (
      <TableRow key={a.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell></TableCell>
        <TableCell>
          <Link to={`edit/${a.id}`}>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "400px",
              }}
            >
              {a.text}
            </Typography>
          </Link>
        </TableCell>

        <TableCell align="right">
          {a.time_from.toFormat("HH:mm")} - {a.time_to.toFormat("HH:mm")}
        </TableCell>
        <TableCell align="right">
          {a.date_from.toFormat("dd/MM/yyyy")} - {a.date_to.toFormat("dd/MM/yyyy")}
        </TableCell>
        <TableCell>
          <FormControlLabel control={<GreenSwitch checked={Boolean(a.active)} onChange={(event) => send("EV_ACTIVATE", { active: event.target.checked, alert: a })} color={a.active ? "primary" : "secondary"} />} label={a.active ? "Aktywny" : "Nieaktywny"} />
        </TableCell>
        <TableCell>
          <IconButton aria-label="delete" color="error" onClick={() => send("EV_DELETE", { id: a.id })}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Stack spacing={2}>
        <Box>
          <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={() => send("EV_ADD")}>
            Nowy wpis
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell width="40%">Treść</TableCell>
                <TableCell align="right">Godziny nadawania</TableCell>
                <TableCell align="right">Data nadawania</TableCell>
                <TableCell width="15%">Status</TableCell>
                <TableCell width="5%"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{entries}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          onChange={(_e, number) => {
            send("EV_PAGE", { page: number - 1 });
          }}
          count={current.context.maxPages}
          showFirstButton
          showLastButton
        />
      </Stack>
    </>
  );
}

function LoadingSkeleton() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Treść</TableCell>
              <TableCell align="right">Godziny nadawania</TableCell>
              <TableCell align="right">Data nadawania</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new Array(5).fill(0, 0, 5).map((x, idx) => {
              return (
                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
