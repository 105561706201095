import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { GlobalContext } from "../global-context";
import { useSelector, useActor } from "@xstate/react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notifications({ messages, ...props }) {
  const globalC = React.useContext(GlobalContext);
  const notifications = globalC.notifications;

  const alert = useSelector(notifications, (state) => state.context.alert);
  const [, send] = useActor(notifications);

  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);

  React.useEffect(() => {
    if (alert && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo(alert);
      setOpen(true);
      send("EV_CLEAR");
    } else if (alert && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [alert, messageInfo, open, send]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} TransitionProps={{ onExited: handleExited }}>
        <Alert onClose={handleClose} severity={messageInfo?.variant ? messageInfo.variant : "success"} sx={{ width: "100%" }}>
          {messageInfo?.message}
        </Alert>
      </Snackbar>
    </>
  );
}
