import { DateTime } from "luxon";
import { axiosFactory } from "./factory";

function mapAlertModel(alert) {
    return {
        ...alert,
        time_from: DateTime.fromISO(alert.time_from),
        time_to: DateTime.fromISO(alert.time_to),
        date_from: DateTime.fromISO(alert.date_from),
        date_to: DateTime.fromISO(alert.date_to)
    }
}

class StockApiImplementation {

    _client = axiosFactory();

    async fetch(perPage, currPage) {

        return this._client.get(`db/stock?perPage=${perPage}&page=${currPage}`)
            .then((response) => {
                return {
                    items: response.data.map(d => mapAlertModel(d)),
                    count: +response.headers["x-total-count"]
                }
            })
    }

    async get(id) {
        return this._client.get(`db/stock/${id}`).then(response => {
            return mapAlertModel(response.data);
        }
        );
    }

    async create(data) {
        return this._client.post(`db/stock`, {
            ...data,
        }).then(response => {
            return mapAlertModel(response.data);

        });
    }

    async delete(id) {
        return this._client.delete(`db/stock/${id}`).then(response => response.data);
    }

    async deleteBulk(ids) {
        return this._client.delete(`db/stock`, { data: ids }).then(response => response.data);
    }

    async update(id, alert) {
        return this._client.put(`db/stock/${id}`, {
            ...alert,
        }).then((response) => {
            return mapAlertModel(response.data);
        })
    }

    async activate(id) {
        return this._client.put(`db/stock/${id}`, {
            active: 1
        }).then((response) => response.data)
    }

    async deactivate(id) {
        return this._client.put(`db/stock/${id}`, {
            active: 0
        }).then((response) => response.data)
    }
}

export const StockApi = new StockApiImplementation();