import React from "react";

import { assign, createMachine } from "xstate";
import { createErrorFromAxiosRejection } from "../../../util";
import axios from "axios";
import AppConfig from "../../../config";
import { useMachine, useActor } from "@xstate/react";
import produce from "immer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";
import { CampaignsApi } from "./../../../api/campaigns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import { GlobalContext } from "../../../global-context";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import { OFFER_STATUS_TRANSLATION } from "./../../../models/YourscreenCampaign";
import { DateTime } from "luxon";

const ListMachine = createMachine(
  {
    id: "list",
    initial: "idle",

    context: {
      api: axios.create({ baseURL: AppConfig.BaseUrl }),
      page: 0,
      maxPages: 0,
      perPage: 10,
      error: null,
      campaigns: [],
      notifications: [],
    },

    states: {
      idle: {
        initial: "fetch",

        states: {
          fetch: {
            invoke: {
              src: "fetch",
              onDone: {
                target: "#list.loaded",
                actions: assign({
                  campaigns: (_, ev) => ev.data.items,
                  maxPages: (context, ev) => Math.ceil(ev.data.count / context.perPage),
                }),
              },
              onError: [
                {
                  target: "notLogged",
                  cond: (_, event) => {
                    const aErr = createErrorFromAxiosRejection(event.data);
                    return aErr.code === 403;
                  },
                },
                {
                  target: "error",
                  cond: (_, event) => {
                    const aErr = createErrorFromAxiosRejection(event.data);
                    return aErr.code !== 404 && aErr.code !== 403;
                  },
                },
                {
                  target: "notFound",
                  cond: (_, event) => {
                    const aErr = createErrorFromAxiosRejection(event.data);
                    return aErr.code === 404;
                  },
                },
              ],
            },
          },
          notLogged: {
            on: {
              EV_TRY_LOGIN: {
                actions: ["doLogin"],
              },
            },
          },
          error: {
            on: {
              EV_RETRY: "fetch",
            },
          },
          notFound: {
            on: {
              EV_ADD: {
                actions: ["doAdd"],
              },
            },
          },
        },
      },

      loaded: {
        initial: "idle",
        states: {
          idle: {},
        },
        on: {
          EV_NEXT_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (context, _) => context.page + 1,
              }),
            ],
            cond: (context) => context.page + 1 <= context.maxPages - 1,
          },
          EV_PREV_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (context, _) => context.page - 1,
              }),
            ],
            cond: (context) => context.page - 1 >= 0,
          },
          EV_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (_, event) => event.page,
              }),
            ],
            cond: (context, event) => event.page >= 0 && event.page <= context.maxPages - 1,
          },
          EV_LAST_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: (context, _) => context.maxPages - 1,
              }),
            ],
          },
          EV_FIRST_PAGE: {
            target: "#list.idle",
            actions: [
              assign({
                page: 0,
              }),
            ],
          },
        },
      },
    },
  },
  {
    services: {
      fetch: (context, _) => {
        return CampaignsApi.fetch(context.perPage, context.page);
      },
    },
  }
);

export default function CampaignList() {
  const history = useNavigate();

  const globalC = React.useContext(GlobalContext);
  const notifications = globalC.notifications;
  const auth = globalC.auth;

  const [current, send] = useMachine(ListMachine, {
    context: {
      notifications: notifications,
    },
    actions: {
      doLogin: () => {
        auth.send("EV_LOGOUT");
      },
      doAdd: () => {
        history("add");
      },
    },
  });

  return (
    <Container className="list">
      {current.matches("idle.fetch") && <LoadingSkeleton />}

      {current.matches("loaded") && <Show current={current} send={send} />}

      {current.matches("idle.notFound") && <Empty send={send} />}

      {current.matches("idle.notLogged") && <NotLogged send={send} />}
    </Container>
  );
}

function Empty({ send }) {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Alert sx={{ mb: "2rem" }} severity="info">
              Brak kampani
            </Alert>
            <Typography variant="body2" color="text.secondary">
              Nie znaleziono żadnych kampani
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

function NotLogged({ send }) {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Alert sx={{ mb: "2rem" }} severity="info">
              Sesja wygasła
            </Alert>
            <Typography variant="body2" color="text.secondary">
              Aby móc korzystać z panelu zaloguj się ponownie
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => send("EV_TRY_LOGIN")} variant="contained" color="secondary">
              Zaloguj się
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

function fillDigits(value, digitCount) {
  var zerosNum = digitCount - value.toString().length + 1;
  return Array(+(zerosNum > 0 && zerosNum)).join("0") + value;
}

function getClient(campaign) {
  if (campaign.end_payment === 1 && campaign.EndClient) {
    return campaign.EndClient;
  }

  if (campaign.end_payment === 2 && campaign.Agency) {
    return campaign.Agency;
  }

  return null;
}

function translateStatus(value) {
  if (OFFER_STATUS_TRANSLATION.has(value)) return OFFER_STATUS_TRANSLATION.get(value).name;
  return "Nieznany status";
}

function Show({ current, send }) {
  const entries = current.context.campaigns.map((a) => {
    return (
      <TableRow key={a.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>{fillDigits(a.id, 9)}</TableCell>
        <TableCell>{a.name}</TableCell>
        <TableCell>{getClient(a).name}</TableCell>
        <TableCell>{a.Owner.personal_data}</TableCell>
        <TableCell>{translateStatus(a.CurrentStatus)}</TableCell>
        <TableCell>{a.Networks[0].group_name}</TableCell>
        <TableCell>{DateTime.fromSeconds(a.StartOffer.date_from).toFormat("dd/MM/yyyy")}</TableCell>
        <TableCell>{DateTime.fromSeconds(a.EndOffer.date_to).toFormat("dd/MM/yyyy")}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <Stack spacing={2}>
        <Box></Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Numer</TableCell>
                <TableCell>Nazwa</TableCell>
                <TableCell>Klient</TableCell>
                <TableCell>Sprzedawca</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Sieć</TableCell>
                <TableCell>Od</TableCell>
                <TableCell>Do</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{entries}</TableBody>
          </Table>
        </TableContainer>
        <Pagination
          onChange={(_e, number) => {
            send("EV_PAGE", { page: number - 1 });
          }}
          page={current.context.page + 1}
          count={current.context.maxPages}
          showFirstButton
          showLastButton
        />
      </Stack>
    </>
  );
}

function LoadingSkeleton() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Treść</TableCell>
              <TableCell align="right">Godziny nadawania</TableCell>
              <TableCell align="right">Data nadawania</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {new Array(5).fill(0, 0, 5).map((x, idx) => {
              return (
                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
