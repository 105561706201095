import { Container } from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "@xstate/react";
import { useContext } from "react";
import { GlobalContext } from "./../../global-context";

import IMG_LOGO from "./../../resources/images/logo.png";
import LoginForm from "./login-form";
import Token from "./token";

import "./index.scss";
import NoToken from "./noToken";

export default function Login() {
  const globalC = useContext(GlobalContext);
  const auth = globalC.auth;
  const isLoggedIn = useSelector(auth, (state) => state.matches("logged"));

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

 

  return (
    <>
      <div className="login">
        <Container className="login__panel">
          <img src={IMG_LOGO} alt="yourscreen logo" />
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/token" element={<Token />} />
            <Route path="/notoken" element={<NoToken />} />
          </Routes>
        </Container>
      </div>
    </>
  );
}
