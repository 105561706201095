import { useContext } from "react";
import { Button, CardActions, Alert } from "@mui/material";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector, useActor } from "@xstate/react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalContext } from "../../global-context";

const Schema = yup.object().shape({
  username: yup.string().email("Musisz podać poprawny adres e-mail").required("Login jest wymagany").label("Login"),
  password: yup.string().required("Hasło jest wymagane").label("Hasło").min(4, "Hasło musi zawierać minimum 4 znaki"),
});

export default function LoginForm() {
  const globalC = useContext(GlobalContext);
  const auth = globalC.auth;
  const isSubmitting = useSelector(auth, (state) => state.matches("loggin_in"));
  const [authState] = useActor(auth);

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Schema),
  });

  return (
    <>
      <form
        className="form"
        onSubmit={handleSubmit((data) => {
          auth.send("EV_LOGIN", { data });
        })}
      >
        <Card className="login__panel-card">
          <CardContent>
            <Typography variant="h4">Logowanie</Typography>

            {authState.context.error?.code === 404 && (
              <Alert severity="error" sx={{ m: "1rem" }}>
                Błędny login lub hasło
              </Alert>
            )}

            {authState.context.error && authState.context.error?.code !== 404 && (
              <Alert severity="error" sx={{ m: "1rem" }}>
                Błąd serwera, spróbuj ponownie później
              </Alert>
            )}

            <Controller name="username" control={control} defaultValue="" render={({ field: { onChange, value }, fieldState: { error } }) => <TextField label="Login" className="form__field" variant="standard" disabled={isSubmitting} value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />} />
            <Controller name="password" control={control} defaultValue="" render={({ field: { onChange, value }, fieldState: { error } }) => <TextField label="Hasło" className="form__field" variant="standard" disabled={isSubmitting} type="password" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />} />
          </CardContent>
          <CardActions className="actions">
            <Button type="submit" disabled={isSubmitting} variant="contained" color="secondary">
              ZALOGUJ
            </Button>
            <a href="#">Nie pamiętasz hasła ?</a>
          </CardActions>
        </Card>
      </form>
    </>
  );
}
