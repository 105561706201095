import { Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";

import { useContext } from "react";
import { useSelector } from "@xstate/react";
import { GlobalContext } from "./global-context";

import Login from "./routes/Login";
import Dashboard from "./routes/Dashboard";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import "./App.scss";

import { RequireAuth } from "./common/require-auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

library.add(faTrashAlt, faCopy);

export default function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Gotham,Roboto,Helvetica,Arial,sans-serif",
    },
    palette: {
      primary: {
        main: "#253068",
        light: "#545996",
        dark: "#00083d",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#59bec0",
        light: "#8ef1f3",
        dark: "#1a8d90",
        contrastText: "#ffffff",
      },
    },
  });

  const globalC = useContext(GlobalContext);
  const auth = globalC.auth;
  const isLoggedIn = useSelector(auth, (state) => state.matches("logged"));
  const isLoading = useSelector(auth, (state) => state.matches("init"));
  const redirect = isLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/login" />;
  const { data } = useVisitorData();

  console.log(data);

  return (
    <>
      <ThemeProvider theme={theme}>
        {isLoading && (
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {!isLoading && (
          <>
            <Routes>
              <Route exact path="/" element={redirect} />
              <Route path="/login/*" element={<Login />} />
              <Route
                path="/dashboard/*"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
            </Routes>
          </>
        )}
      </ThemeProvider>
    </>
  );
}
