
import APP_ERROR_CODES from './error-codes';

export function createErrorFromAxiosRejection(err) {

    if (!err)
        return null;

    // no internet special case
    if (err.message === "Network Error") {
        return {
            message: err.message,
            code: APP_ERROR_CODES.INTERNET_CONNECTION_ERROR
        }
    }

    return {
        message: err.response.message,
        code: err.response.status,
        data: err.response.data
    }
}
