import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { GlobalContextProvider } from "./global-context";
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

Sentry.init({
  dsn: "https://c41b5ea14a024a509b4ea69465b81f76@o1114498.ingest.sentry.io/6145744",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalContextProvider>
        <FpjsProvider
          loadOptions={{
            apiKey: "ku4PGf1A2Gk0WaPV2dNA", region: "eu"
          }}
        >
          <App />
        </FpjsProvider>
      </GlobalContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
