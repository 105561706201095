import { axiosFactory } from "./factory";
import { YourscreenCampaign } from "./../models/YourscreenCampaign"
class CampaignsApiImplementation {
  _client = axiosFactory();

  async fetch(perPage, currPage) {
    return this._client.get(`/db/arrowcampaigns/list?perPage=${perPage}&page=${currPage}&search={}&order=desc&column=id`).then((response) => {
      return {
        items: response.data.map((c) => new YourscreenCampaign(c)),
        count: +response.headers["x-total-count"],
      };
    });
  }
}

export const CampaignsApi = new CampaignsApiImplementation();
