import { DateTime, Interval } from "luxon";
import _ from "lodash";

export const FILE_TYPES = {
  SPOT: 3,
  STATISTICS: 4,
  PHOTO: 5,
  INVOICE: 6,
  ORDER: 2,
};

export const OFFER_STATUS = {
  SENDED: 1,
  SIGNED: 3,
  EMIT: 4,
  ENDED: 5,
  READY: 6,
  CONFIRMED: 2,
  CANCELLED: 7,

  UNKNOWN: 0,

  ToArray: function () {
    let arr = [];
    arr.push({ id: this.SENDED, name: "Wysłana" });
    arr.push({ id: this.CONFIRMED, name: "Potwierdzona" });
    arr.push({ id: this.SIGNED, name: "Podpisana" });
    arr.push({ id: this.EMIT, name: "Emitowana" });
    arr.push({ id: this.ENDED, name: "Zakończona" });
    arr.push({ id: this.READY, name: "Gotowa do emisji" });
    arr.push({ id: this.UNKNOWN, name: "Nieznany status" });
    arr.push({ id: this.CANCELLED, name: "Anulowana" });

    return arr;
  },
};

export const OFFER_STATUS_TRANSLATION = new Map();
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.SENDED, { name: "Wysłana" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.CONFIRMED, { name: "Potwierdzona" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.SIGNED, { name: "Podpisana" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.EMIT, { name: "Emitowana" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.ENDED, { name: "Zakończona" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.READY, { name: "Gotowa do emisji" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.UNKNOWN, { name: "Nieznany status" });
OFFER_STATUS_TRANSLATION.set(OFFER_STATUS.CANCELLED, { name: "Anulowana" });

export class YourscreenCampaign {
  constructor(data) {
    Object.assign(this, data);
  }

  get OffersExceptCustom() {
    return _.filter(this.Offers, (o) => {
      return o.type != "custom";
    });
  }

  get CurrentStatus() {
    if (this.IsSended) return OFFER_STATUS.SENDED;
    if (this.IsConfirmed) return OFFER_STATUS.CONFIRMED;
    if (this.IsSigned) return OFFER_STATUS.SIGNED;
    if (this.IsReadyToEmit) return OFFER_STATUS.READY;
    if (this.IsEmited) return OFFER_STATUS.EMIT;
    if (this.IsEnded) return OFFER_STATUS.ENDED;

    return OFFER_STATUS.UNKNOWN;
  }

  get StartOffer() {
    return _.minBy(this.OffersExceptCustom, (o) => {
      return o.date_from;
    });
  }

  get EndOffer() {
    return _.maxBy(this.OffersExceptCustom, (o) => {
      return o.date_to;
    });
  }

  get Networks() {
    let val = _.uniqBy(
      _.map(this.OffersExceptCustom, (o) => {
        return o.Network;
      }),
      "group_id"
    );

    return val;
  }

  get Localisations() {
    let val = _.uniqBy(
      _.map(
        _.flatMap(this.OffersExceptCustom, (o) => {
          return o.Locations;
        }),
        function (e) {
          return e.Localisation;
        }
      ),
      "id"
    );

    return val;
  }

  get HaveOrder() {
    return _.some(this.Files, (f) => {
      return f.type == FILE_TYPES.ORDER;
    });
  }

  get HaveSpot() {
    // return _.every(this.OffersExceptCustom, (o) => {
    //     return o.Spots.length != 0;
    // })

    return this.MainOffer.Spots.length != 0;
  }

  get EndPayer() {
    if (this.end_payment == 1 && this.EndClient) {
      return this.EndClient;
    }

    if (this.end_payment == 2 && this.Agency) {
      return this.Agency;
    }

    return null;
  }

  GetCurrentOffers() {
    const now = DateTime.now();
    const current = _.filter(this.OffersExceptCustom, (o) => {
      return Interval.fromDateTimes(DateTime.fromSeconds(o.date_from), DateTime.fromSeconds(o.date_to)).contains(now);
    });

    return _.sortBy(current, "date_from");
  }

  GetNearestOffer() {
    const now = DateTime.now();
    const nearest = _.minBy(this.OffersExceptCustom, (o) => {
      return DateTime.fromSeconds(o.date_from).diff(now, "day");
    });

    return nearest;
  }

  get MainOffer() {
    const offers = this.GetCurrentOffers();
    if (offers.length === 0) {
      return this.GetNearestOffer();
    }

    return offers[0];
  }

  get IsSended() {
    return _.every(this.OffersExceptCustom, (o) => {
      return o.status === OFFER_STATUS.SENDED;
    });
  }

  get IsConfirmed() {
    return _.every(this.OffersExceptCustom, (o) => {
      return o.status === OFFER_STATUS.CONFIRMED;
    });
  }

  get IsSigned() {
    return _.every(this.OffersExceptCustom, (o) => {
      return o.status == OFFER_STATUS.SIGNED;
    });
  }

  get IsReadyToEmit() {
    return this.MainOffer.status == OFFER_STATUS.READY;
  }

  get IsEmited() {
    return this.MainOffer.status == OFFER_STATUS.EMIT;
  }

  get IsEnded() {
    return _.every(this.OffersExceptCustom, (o) => {
      return o.status == OFFER_STATUS.ENDED;
    });
  }

  get AllSpots() {
    let spots = [];
    _.each(this.OffersExceptCustom, (o) => {
      o.Spots.forEach((s) => {
        s.Owner = o;
      });
      spots = spots.concat(o.Spots);
    });

    return _.uniqBy(spots, (s) => s.File.friendly_name);
  }

  get AllSpotsGrouped() {
    let spots = [];
    _.each(this.OffersExceptCustom, (o) => {
      o.Spots.forEach((s) => {
        s.Owner = o;
      });
      spots = spots.concat(o.Spots);
    });

    const v = _.mapValues(
      _.groupBy(spots, (s) => s.File.friendly_name),
      (k) => {
        return _.mapKeys(
          _(k)
            .groupBy((s) => s.Owner.Network.group_id)
            .map((x) => {
              const players = _.uniqBy(x.map((d) => d.Owner.Locations.map((xx) => xx.Localisation.Computers).flat()).flat(), (p) => p.id_sl);
              const slide = x.find((s) => players.length !== 0 && s.Owner.Network.group_id === players[0].Network.group_id);
              return {
                players,
                slide,
              };
            })
            .value(),
          (val, key) => {
            if (val.players.length === 0) {
              return "Nieznana grupa";
            }
            return val.players[0].Network.group_name;
          }
        );
      }
    );

    return v;
  }
}
