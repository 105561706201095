import React from "react";
import { useInterpret } from "@xstate/react";
import { useNavigate } from "react-router-dom";
import AuthMachine from "./machines/auth-machine";
import NotificationMachine from "./machines/notifications-machine";

const STORAGE_KEY = "GLOBAL_STATE";

export const GlobalContext = React.createContext({
  auth: null,
  notifications: null,
});

export const GlobalContextProvider = (props) => {
  const history = useNavigate();

  const authService = useInterpret(AuthMachine, {
    actions: {
      onUserFound: (_c, _ev) => {
        history({ pathname: "/login/token" }, { replace: true });
      },
      onNoToken: () => {
        debugger;
        history({ pathname: "/login/noToken" }, { replace: true });
      },
    },
  });

  const notificationsService = useInterpret(NotificationMachine);

  return (
    <GlobalContext.Provider
      value={{
        auth: authService,
        notifications: notificationsService,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
