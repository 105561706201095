import axios from "axios";
import AppConfig from "../config";

export let CURRENT_USER = null;

export function axiosFactory() {
    const instance = axios.create({ baseURL: AppConfig.BaseUrl });
    instance.interceptors.request.use(function (config) {
        if (!CURRENT_USER) {
            const data = window.localStorage.getItem("CURRENT_USER");
            if (data) {
                CURRENT_USER = JSON.parse(data);
            }
        }

        if (CURRENT_USER) {
            config.headers.Authorization = `Bearer ${CURRENT_USER.token}`;
        }

        // Do something before request is sent
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });


    return instance;
}

export function setCurrentUser(user) {
    CURRENT_USER = user;
}
