import { useContext } from "react";
import { Button, CardActions } from "@mui/material";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "@xstate/react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GlobalContext } from "../../global-context";
import { Navigate } from "react-router-dom";

const Schema = yup.object().shape({
  token: yup.number().required("Login jest wymagany").label("Token"),
});

export default function Token() {
  const globalC = useContext(GlobalContext);
  const auth = globalC.auth;
  const isSubmitting = useSelector(auth, (state) => state.matches("token_in"));
  const isLogged = useSelector(auth, (state) => state.matches("logged"));
  const isNotLogged = useSelector(auth, (state) => state.matches("not_logged"));
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(Schema),
  });

  if (isLogged) {
    return <Navigate to="/dashboard" />;
  }

  if (isNotLogged) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <form
        className="form"
        onSubmit={handleSubmit((data) => {
          auth.send("EV_TOKEN", { data });
        })}
      >
        <Card className="login__panel-card">
          <CardContent>
            <Typography variant="h4">Wpisz token</Typography>
            <Typography>Aby się zalogować należy podać token wygenerowany w Google Authenticator</Typography>
            <Controller name="token" control={control} defaultValue="" render={({ field: { onChange, value }, fieldState: { error } }) => <TextField label="Token" className="form__field" variant="standard" disabled={isSubmitting} type="text" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />} />
          </CardContent>
          <CardActions className="actions">
            <Button type="submit" disabled={isSubmitting} variant="contained" color="primary">
              ZALOGUJ
            </Button>
          </CardActions>
        </Card>
      </form>
    </>
  );
}
