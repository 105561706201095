import * as yup from "yup";
import { assign, createMachine } from "xstate";
import { StockApi } from "../../../api/stock";
import { DateTime } from "luxon";
import { useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useParams } from "react-router-dom";
import { useMachine } from "@xstate/react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import SaveIcon from "@mui/icons-material/Save";
import { GlobalContext } from "../../../global-context";

const Schema = yup.object().shape({
  text: yup.string().required("Treść jest wymagana").label("Alert").max(130, "Osiągnięto limit 130 znaków"),
  time_from: yup.string().required("Czas 'Od' jest wymagany").label("Czas od"),
  time_to: yup.string().required("Czas 'Do' jest wymagany").label("Czas do"),
  date_from: yup.date().required("Data 'Od' jest wymagana").label("Data od"),
  date_to: yup
    .date()
    .required("Data 'Do' jest wymagana")
    .label("Data do")
    .test("is-greater", "Data 'do' powinien być późniesza lub równa dacie startu", function (val) {
      const { date_from } = this.parent;
      const time = DateTime.fromMillis(Date.parse(val));
      const start = DateTime.fromMillis(Date.parse(date_from));
      debugger;
      return start.startOf("day") <= time.startOf("day");
    }),
});

const FormMachine = createMachine(
  {
    id: "form",
    initial: "idle",

    context: {
      alert: {
        time_from: DateTime.fromObject({ hour: 6, minute: 0 }),
        time_to: DateTime.fromObject({ hour: 22, minute: 0 }),
        date_from: DateTime.now(),
        date_to: DateTime.now(),
        text: "",
        active: 0,
      },
    },

    states: {
      idle: {
        on: {
          EV_LOAD: [
            {
              target: "load",
              cond: (context, event) => event.alert !== null,
            },
            {
              target: "loaded",
              cond: (context, event) => event.alert === null,
              actions: ["onReset"],
            },
          ],
        },
      },
      load: {
        invoke: {
          src: "fetch",
          onDone: {
            target: "loaded",
            actions: [
              assign({
                alert: (_, event) => event.data[0],
              }),
              "onReset",
            ],
          },
          onError: {
            target: "error",
            actions: [
              (context, _ev) => {
                context.notifications.send("EV_SHOW", { alert: { message: "Nie można pobrać danych do formularza", variant: "error" } });
              },
            ],
          },
        },
      },
      loaded: {
        on: {
          EV_SAVE: "save",
        },
      },
      save: {
        invoke: {
          src: "save",
          onDone: {
            target: "loaded",
            actions: [
              (context, _ev) => {
                context.notifications.send("EV_SHOW", { alert: { message: "Zapisano pomyślnie dane formularza.", variant: "success" } });
              },
            ],
          },
          onError: {
            target: "loaded",
            actions: [
              (context, _ev) => {
                context.notifications.send("EV_SHOW", { alert: { message: "Nie można zapisać danych formularza.", variant: "error" } });
              },
            ],
          },
        },
      },
      error: {
        on: {
          EV_RETRY: "load",
        },
      },
    },
  },
  {
    services: {
      fetch: (_, event) => {
        return Promise.all([event.alert ? StockApi.get(event.alert) : null]);
      },
      save: (context, event) => {
        const dto = { ...event.alert, date_from: event.alert.date_from.toISOString(), date_to: event.alert.date_to.toISOString(), time_from: event.alert.time_from.toISOString(), time_to: event.alert.time_to.toISOString() };

        if (context.alert?.id > 0) {
          return StockApi.update(context.alert.id, dto);
        }

        return StockApi.create(dto);
      },
    },
  }
);

export default function StockMessage() {
  const globalC = useContext(GlobalContext);
  const notifications = globalC.notifications;

  const { handleSubmit, reset, control } = useForm({ mode: "onChange", resolver: yupResolver(Schema) });

  const history = useNavigate();
  const params = useParams();

  const [current, send] = useMachine(FormMachine, {
    context: {
      notifications: notifications,
    },
    actions: {
      onSuccess: () => {
        history("/dashboard");
      },
      onReset: ({ alert }) => {
        reset({
          text: alert.text,
          date_from: alert.date_from.toJSDate(),
          date_to: alert.date_to.toJSDate(),
          time_from: alert.time_from.toJSDate(),
          time_to: alert.time_to.toJSDate(),
        });
      },
    },
  });

  useEffect(() => {
    send("EV_LOAD", { alert: params.id ?? null });
  }, [params, send]);

  return (
    <>
      {(current.matches("load") || current.matches("idle")) && <Placeholder />}

      {current.matches("error") && <Error send={send} />}
      {(current.matches("loaded") || current.matches("save") || current.matches("success")) && (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <form
            onSubmit={handleSubmit((data) => {
              
              send("EV_SAVE", { alert: {
                ...data,
                active: 0,
                time_from: new Date(data.time_from),
                time_to: new Date(data.time_to),

              } });
            })}
          >
            <Container>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Box sx={{ width: "50%" }}>
                  <Controller name="text" control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <TextField fullWidth id="outlined-multiline-static" label="Treść komunikatu" multiline rows={4} variant="standard" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />} />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ mb: "1rem" }}>Daty nadawania</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Controller name="date_from" control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <DesktopDatePicker label="Od" inputFormat="MM/dd/yyyy" value={value} onChange={onChange} renderInput={(params) => <TextField disabled={current.matches("save")} {...params} error={!!error} helperText={error ? error.message : null} />} />} />
                    </Grid>
                    <Grid item xs={5}>
                      <Controller name="date_to" control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <DesktopDatePicker label="Do" inputFormat="MM/dd/yyyy" value={value} onChange={onChange} renderInput={(params) => <TextField {...params} error={!!error} helperText={error ? error.message : null} />} />} />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ mb: "1rem" }}>Godziny nadawania</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <Controller name="time_from" control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <TimePicker ampm={false} label="Od" value={value} onChange={onChange} renderInput={(params) => <TextField {...params} error={!!error} helperText={error ? error.message : null} />} />} />
                    </Grid>
                    <Grid item xs={5}>
                      <Controller name="time_to" control={control} render={({ field: { onChange, value }, fieldState: { error } }) => <TimePicker ampm={false} label="Do" value={value} onChange={onChange} renderInput={(params) => <TextField disabled={current.matches("save")} {...params} error={!!error} helperText={error ? error.message : null} />} />} />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Button disabled={current.matches("save")} sx={{ mr: "1rem" }} type="submit" variant="contained" color="secondary" startIcon={<SaveIcon />}>
                    Zapisz
                  </Button>
                  <Button disabled={current.matches("save")} onClick={() => history("/dashboard/stock")} variant="outlined" color="warning">
                    Wstecz
                  </Button>
                </Box>
              </Stack>
            </Container>
          </form>
        </LocalizationProvider>
      )}
    </>
  );
}

function Error({ send }) {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Alert severity="error">Wystąpił błąd przy pobieraniu danych</Alert>
            <Typography variant="body2" color="text.secondary">
              Wystąpił błąd na serwerze i nie udało się załadować danych. Spróbuj ponownie później.
            </Typography>
          </CardContent>
          <CardActions>
            <Button onClick={() => send("EV_RETRY")} variant="contained" color="secondary">
              Spróbuj ponownie
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

function Placeholder() {
  return (
    <>
      <Container>
        <Stack spacing={2}>
          <Box>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Box>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Stack>
      </Container>
    </>
  );
}
