import { createMachine, assign } from "xstate";
import AppConfig from "../config";
import axios from "axios";
import { createErrorFromAxiosRejection } from "./../util";
import { setCurrentUser } from "./../api/factory";

export const CURRENT_USER_LOCAL_STORAGE_SLUG = "CURRENT_USER";

const api = axios.create({ baseURL: AppConfig.BaseUrl });
const AuthMachine = createMachine(
  {
    id: "AuthMachine",
    initial: "init",

    context: {
      credentials: null,
      user: null,
      error: null,
      loginResult: null,
    },

    states: {
      init: {
        invoke: {
          src: "getUserFromLocalStorage",
          onDone: {
            target: "logged",
            actions: ["assignUser", "clearError"],
          },
          onError: {
            target: "not_logged.no_error",
            actions: ["clearLocalStorage", "clearUser"],
          },
        },
      },
      not_logged: {
        initial: "no_error",
        on: {
          EV_LOGIN: "loggin_in",
        },
        states: {
          no_error: {},
          error: {},
        },
      },
      loggin_in: {
        invoke: {
          src: "tryLogin",
          onDone: [
            {
              cond: "haveToken",
              target: "token",
              actions: ["clearError", "assignLoginResult", "onUserFound"],
            },
            {
              cond: "noToken",
              target: "noToken",
              actions: ["clearError", "assignLoginResult", "assignTokenQR","onNoToken"],
            },
          ],
          onError: {
            target: "not_logged.error",
            actions: ["clearLocalStorage", "clearUser", "assignError"],
          },
        },
      },
      noToken: {
        on: {
          EV_NEXT: "token",
        },
      },
      token: {
        initial: "no_error",
        on: {
          EV_TOKEN: "token_in",
        },
        states: {
          no_error: {},
          error: {},
        },
      },
      token_in: {
        invoke: {
          src: "tryToken",
          onDone: {
            target: "logged",
            actions: ["clearError", "assignUser", "setUserInLocalStorage"],
          },
          onError: {
            target: "token.error",
            actions: ["clearLocalStorage", "assignError"],
          },
        },
      },
      logged: {
        on: {
          EV_LOGOUT: "loggin_out",
        },
      },
      loggin_out: {
        invoke: {
          src: "tryLogOut",
          onDone: {
            target: "not_logged.no_error",
            actions: ["clearLocalStorage", "clearUser", "clearError"],
          },
          onError: {
            target: "logged",
            actions: "assignError",
          },
        },
      },
    },
  },
  {
    guards: {
      haveToken: (context, event) => {
        return event.data.data.first_time === false;
      },
      noToken: (context, event) =>{ 
        return event.data.data.first_time === true;
      }
    },
    services: {
      tryToken: (context, event) => {
        return api.post("/users/authtoken", {
          token: event.data.token,
          username: context.loginResult.data.user.email,
        });
      },
      tryLogin: (context, event) => {
        return api.post("/users/login", event.data);
      },
      tryLogOut: async (context, event) => {
        // do nothing, in this case we use JWT TOKEN
      },
      getUserFromLocalStorage: async () => {
        const data = window.localStorage.getItem(CURRENT_USER_LOCAL_STORAGE_SLUG);

        if (!data) {
          throw new Error("No user found in local storage");
        }

        return JSON.parse(data);
      },
    },
    actions: {
      clearError: assign({
        error: (_c, _e) => null,
      }),
      assignError: assign({
        error: (_c, event) => {
          return createErrorFromAxiosRejection(event.data);
        },
      }),
      clearUser: assign({
        user: (_c, _e) => null,
      }),
      assignLoginResult: assign({
        loginResult: (_c, event) => event.data,
      }),
      assignUser: assign({
        user: (_c, event) => {
          return event.data.data;
        },
      }),
      clearLocalStorage: () => {
        window.localStorage.removeItem(CURRENT_USER_LOCAL_STORAGE_SLUG);
      },
      setUserInLocalStorage: (c, event) => {
        window.localStorage.setItem(CURRENT_USER_LOCAL_STORAGE_SLUG, JSON.stringify(c.user));
        setCurrentUser(c.user);
      },
    },
  }
);

export default AuthMachine;
